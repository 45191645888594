// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-check-license-en-js": () => import("./../src/pages/check-license.en.js" /* webpackChunkName: "component---src-pages-check-license-en-js" */),
  "component---src-pages-check-license-mm-js": () => import("./../src/pages/check-license.mm.js" /* webpackChunkName: "component---src-pages-check-license-mm-js" */),
  "component---src-pages-download-latest-apk-js": () => import("./../src/pages/download-latest-apk.js" /* webpackChunkName: "component---src-pages-download-latest-apk-js" */),
  "component---src-pages-financial-tip-mm-js": () => import("./../src/pages/financial-tip.mm.js" /* webpackChunkName: "component---src-pages-financial-tip-mm-js" */),
  "component---src-pages-help-center-en-js": () => import("./../src/pages/help-center.en.js" /* webpackChunkName: "component---src-pages-help-center-en-js" */),
  "component---src-pages-help-center-mm-js": () => import("./../src/pages/help-center.mm.js" /* webpackChunkName: "component---src-pages-help-center-mm-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-mm-js": () => import("./../src/pages/index.mm.js" /* webpackChunkName: "component---src-pages-index-mm-js" */),
  "component---src-pages-investment-terms-js": () => import("./../src/pages/investment-terms.js" /* webpackChunkName: "component---src-pages-investment-terms-js" */),
  "component---src-pages-loan-calculator-en-js": () => import("./../src/pages/loan-calculator.en.js" /* webpackChunkName: "component---src-pages-loan-calculator-en-js" */),
  "component---src-pages-loan-calculator-mm-js": () => import("./../src/pages/loan-calculator.mm.js" /* webpackChunkName: "component---src-pages-loan-calculator-mm-js" */),
  "component---src-pages-mothercredit-js": () => import("./../src/pages/mothercredit.js" /* webpackChunkName: "component---src-pages-mothercredit-js" */),
  "component---src-pages-mothercredit-privacy-policy-js": () => import("./../src/pages/mothercredit/privacy-policy.js" /* webpackChunkName: "component---src-pages-mothercredit-privacy-policy-js" */),
  "component---src-pages-mothercredit-terms-conditions-js": () => import("./../src/pages/mothercredit/terms-conditions.js" /* webpackChunkName: "component---src-pages-mothercredit-terms-conditions-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-mm-js": () => import("./../src/pages/privacy-policy.mm.js" /* webpackChunkName: "component---src-pages-privacy-policy-mm-js" */),
  "component---src-pages-terms-conditions-en-js": () => import("./../src/pages/terms-conditions.en.js" /* webpackChunkName: "component---src-pages-terms-conditions-en-js" */),
  "component---src-pages-terms-conditions-mm-js": () => import("./../src/pages/terms-conditions.mm.js" /* webpackChunkName: "component---src-pages-terms-conditions-mm-js" */),
  "component---src-templates-article-detail-mm-js": () => import("./../src/templates/articleDetail.mm.js" /* webpackChunkName: "component---src-templates-article-detail-mm-js" */),
  "component---src-templates-articles-mm-js": () => import("./../src/templates/articles.mm.js" /* webpackChunkName: "component---src-templates-articles-mm-js" */),
  "component---src-templates-financial-tip-detail-mm-js": () => import("./../src/templates/financialTipDetail.mm.js" /* webpackChunkName: "component---src-templates-financial-tip-detail-mm-js" */),
  "component---src-templates-news-en-js": () => import("./../src/templates/news.en.js" /* webpackChunkName: "component---src-templates-news-en-js" */),
  "component---src-templates-news-mm-js": () => import("./../src/templates/news.mm.js" /* webpackChunkName: "component---src-templates-news-mm-js" */)
}

