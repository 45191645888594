import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/css/bootstrap-grid.min.css"
import "./src/styles/app.css"
import "react-bootstrap"
import "./src/asset/font-awesome/css/all.css"

const injectFacebookSdkScript = () => {
  const addJS = () => {
    let s = document.createElement(`script`)

    s.type = `text/javascript`
    s.src =
      "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v8.0&appId=232887290070960&autoLogAppEvents=1"
    s.innerText = 'async defer crossorigin="anonymous" nonce="HRVoivFu"'
    document.getElementsByTagName(`head`)[0].appendChild(s)
  }

  addJS()
}

let injectedFBScript = false

export const onRouteUpdate = () => {
  // If there's an embedded element, lazy-load the twitter script (if it hasn't
  // already been loaded), and then run the twitter load function.
  if (!injectedFBScript) {
    injectFacebookSdkScript()
    injectedFBScript = true
  } else {
      window.FB.init({
        appId      : '232887290070960',
        status     : true,
        xfbml      : true,
        version    : 'v2.7'
      });
  }
}
